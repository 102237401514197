<template>
  <div class="wrap">
    <div class="top-banner">
    </div>
    <div class="development-box w-1200">
        <div class="development-item">
          <label>数邦科技的注册成立</label>
          <p>由在渝博士后和大数据领域资深专家共同发起创立，是“重庆市博士后沙龙走进高新区活动”后首个落地育成孵化器的博士后创新创业项目，注册成立于2020年9月30日。</p>
        </div>
        <div class="development-item">
          <label>数邦科技的发展愿景</label>
          <p>为用户提供集数据规划咨询、采集、分析及智能化应用的一体化解决方案，致力于打造成为“智慧城市的数据综合服务商”。</p>
        </div>
        <div class="development-item">
          <label>数邦科技的发展定位</label>
          <p>致力于围绕智慧城市的数据政用、民用和商用生态链的应用场景，以“咨询+技术实现”为双轮驱动，构建“数据咨询规划+大数据分析+AI智能化应用”的核心技术闭环。</p>
        </div>
        <div class="development-item">
          <label>数邦科技在研产品生态</label>
          <p>数邦实时数据捕获（DU-RDC）、数邦实时数据处理平台（DU-RDP）、数邦大数据平台（DU-UDP） 、数邦数据服务平台（DU-DSP） 、数邦机器学习平台（DU-LMP）</p>
        </div>
    </div>
    <div class="development-time-box w-1200">
      <div class="title">发展历程</div>
      <ul>
        <li>
          <div class="time">2019</div>
          <p>初创团队成立</p>
        </li>
        <li>
          <div class="time">2020</div>
          <p>
						博士后团队加入
						<br/>
						中国赛宝大数据方案合作
					</p>
        </li>
				<li>
					<div class="time">2020 </div>
					<p>
						重庆深联
						<br/>
						成都瑞思齐
					</p>
					<div class="tag strategy">战略合作</div>
				</li>
        <li>
          <div class="time">2021</div>
          <div class="tag project">项目</div>
          <p>重庆银行<br/>重庆农商行<br/>重庆公积金中心<br/>重庆电力大数据分析</p>
        </li>
				<li>
					<div class="time">2022</div>
					<div class="tag project">项目</div>
					<p>重庆綦江电力<br/>数据挖掘技术服务</p>
				</li>
				<!-- <li>
					<div class="time">2022</div>
					<p>深耕信息科技的深联	</p>
					<div class="tag strategy">战略合作</div>
				</li> -->
        <li>
          <div class="time">2023</div>
          <p>正在发生......</p>
        </li>
      </ul>
      <div class="system-box">
        <div class="system-detail">
          <div class="img-box">
            <img src="~@/assets/images/about/certificate.png"/>
            <img src="~@/assets/images/about/certificate.png"/>
            <img src="~@/assets/images/about/certificate.png"/>
          </div>
          <div class="text-box">
            <div class="tag">研发</div>
            <p>消费金融核心系统</p>
            <p>贷前审批系统</p>
            <p>贷后管理催收系统</p>
          </div>
        </div>
        <div class="system-detail">
          <div class="img-box">
            <img src="~@/assets/images/about/certificate.png"/>
            <img src="~@/assets/images/about/certificate.png"/>
            <img src="~@/assets/images/about/certificate.png"/>
          </div>
          <div class="text-box">
            <div class="tag">研发</div>
            <p>实时数据捕获平台</p>
            <p>机器学习平台</p>
            <p>大数据平台</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.top-banner {
  height: 660px;
  background-image: url('~@/assets/images/about/banner.jpg');
  background-repeat: no-repeat;
  background-position: center;
}
.development-box {
  padding: 40px 0;
  .development-item {
    margin-bottom: 77px;
    label {
      font-size: 20px;
      font-weight: 500;
      color: #1D2129;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 88px;
        height: 1px;
        top: 39px;
        left: 0;
        background-color: #1D2129;
      }
    }
    p {
      font-size: 20px;
      font-weight: 500;
      color: #4F5969;
      margin-top: 39px;
      line-height: 1.2;
    }
  }
  
}
.development-time-box {
  padding-bottom: 65px;
  .title {
    font-size: 48px;
    font-weight: 600;
    color: #040404;
    text-align: center;
    margin-bottom: 83px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 88px;
      height: 1px;
      top: 65px;
      left: 50%;
      margin-left: -44px;
      background-color: #1D2129;
    }
  }
  ul {
    overflow: hidden;
    li {
      float: left;
      position: relative;
      padding-bottom: 50px;
			margin-bottom: 30px;
      &:first-child{
        padding-right: 60px;
      }
      &:nth-child(2){
        padding-right: 40px;
      }
      &:nth-child(3){
        padding-right: 95px;
				.tag {
					position: relative;
					top: -100px;
					left: 40px;
				}
      }
      &:nth-child(4){
        padding-right: 50px;
        .tag {
          position: absolute;
          top: 3px;
        }
      }
      &:nth-child(5){
        padding-right: 40px;
        .tag {
          position: absolute;
          left: 90px;
          top: 4px;
        }
      }
      &:nth-child(6){
        padding-right: 80px;
        .tag {
          position: absolute;
          left: 90px;
          top: 0px;
        }
				&:after {
					border-bottom-style: dashed;
				}
      }
      .time {
        display: inline-block;
        font-size: 36px;
        font-weight: 400;
        color: #040404;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: #4F5969;
        margin-top: 21px;
        line-height: 1.2;
      }
      .tag {
        font-size: 16px;
        font-weight: 400;
        color: #4F5969;
        background-color: rgba(26, 43, 128, 0.2);
        border-radius: 2px;
        &.strategy {
          line-height: 1.1;
          width: 41px;
          height: 44px;
          box-sizing: border-box;
          padding: 4px;
					float: right;
        }
        &.project {
          width: 49px;
          height: 30px;
          box-sizing: border-box;
          padding: 7px 8px;
					display: inline-block;
        }
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom-style: solid;
        border-bottom-width: 4px;
        border-bottom-color: rgba(26, 43, 128, 0.2);
        top: 170px;
      }
      &:first-child:before {
        width: 20px;
        height: 20px;
        top: 152px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 11px;
        height: 11px;
        top: 157px;
        background: #1A2B80;
        border: 10px solid #F7F8FA;
        z-index: 2;
        left: -10px;
      }
    }
  }
  .system-box {
    overflow: hidden;
    margin-top: 48px;
    .system-detail {
      float: left;
      overflow: hidden;
      &:first-child {
        margin-right: 260px;
      }
      .img-box {
        float: left;
        img {
          width: 55px;
          margin-right: 8px;
        }
      }
      .text-box {
        margin-left: 201px;
        .tag {
          width: 80px;
          height: 30px;
          background-color: rgba(26, 43, 128, 0.2);
          border-radius: 2px;
          font-size: 16px;
          font-weight: 400;
          color: #4F5969;
          text-align: center;
          line-height: 30px;
          margin-bottom: 19px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #4F5969;
          margin-bottom: 12px;
        }
      }
    } 
    
  }
  
}
</style>